import React from 'react'

import Layout from '../components/layout'
import Container from '../components/container'
import Header from '../components/header'

const DesignPage = () => (
  <Layout>
    <Container>
      <Header roleTitle='designers' />
    </Container>
  </Layout>
)

export default DesignPage
